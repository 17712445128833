.team-vertical {
   display: grid;
   grid-template-columns: 1fr min-content 1.2rem;
   align-items: start;
}

.team-vertical img {
   border-radius: .8rem;
}

.team-vertical h3, .team-vertical h2 {
   writing-mode: vertical-rl;
}
.team-vertical h3 {
   font-family: 'Ubuntu', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
}